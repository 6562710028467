import packageInfo from "@packageJson";
import { getLoginStatus } from "@redux-utils";
import { allowAdminToolbox, allowUserLogin } from "@utils/functions";
import { pathfinderGenerator } from "@utils/route";
import { joinNonEmptyStrings } from "@utils/strings";
import deepmerge from "deepmerge";
// COMMON
import cloudinary from "../cloudinary.json";
import BuyInfo from "../common/BuyInfo";
import Breadcrumbs from "../common/Breadcrumbs";
import Campaign from "../common/Campaign";
import CheckoutDeliveryInfo from "../common/CheckoutDeliveryInfo";
import CheckoutOtherOptions from "../common/CheckoutOtherOptions";
import CheckoutPlaceOrder from "../common/CheckoutPlaceOrder";
import CheckoutRelatedProducts from "../common/CheckoutRelatedProducts";
import CheckoutShipmentPayment from "../common/CheckoutShipmentPayment";
import CheckoutShoppingCart from "../common/CheckoutShoppingCart";
import CheckoutSubtotal from "../common/CheckoutSubtotal";
import CookiePolicy from "../common/CookiePolicy";
import CustomerServiceContact from "../common/CustomerServiceContact";
import CustomerServiceFootnote from "../common/CustomerServiceFootnote";
import CustomerServiceLead from "../common/CustomerServiceLead";
import footer from "../common/Footer";
import GDPRCookieController from "../common/GDPRCookieController";
import ImageFootnoteCardDeck from "../common/ImageFootnoteCardDeck";
import IntegrityPolicy from "../common/IntegrityPolicy";
import MainMenu from "../common/MainMenu";
import MarqueeWidget from "../common/MarqueeWidget";
import menuBarHeader from "../common/MenuBarHeader";
import NotFound404 from "../common/NotFound404";
import paymentMethods from "../common/PaymentMethods";
import ProductBrandInfo from "../common/ProductBrandInfo";
import SearchBanner from "../common/SearchBanner";
import SearchEngine from "../common/SearchEngine";
// SITE-DEPENDENT
import DefaultSite from "../_default";

export default function Site(siteConfig, customConfig) {
  const {
    siteId,
    title,
    i18n,
    siteLogo,
    siteScreens,
    siteTemplates,
    siteRedirects,
    siteStaticContent,
    siteCustomPages,
    siteClassname,
    //siteCurrency,
    widgetsIdentities
  } = siteConfig;

  const userLogin = siteConfig.store.getState().userLogin;

  const loginStatus = getLoginStatus(userLogin);
  const trusted = (loginStatus.loggedUser || {}).trusted;

  const customConfigKeys = customConfig ? Object.keys(customConfig) : null;

  /**
   * @description Resolves the frontend image source URI
   * @param {String} imgDir The image relative directory name (eg. siteId)
   * @param {String} relFilename The image relative filename
   * @returns {String} Returns either the image relative URI path or its content encoded as data URI scheme
   */
  const imgResolver = (imgDir, relFilename) => {
    const defaultValue = imgDir + relFilename;

    if (!customConfig) {
      return defaultValue;
    }

    const filename = imgDir + "/frontend/images" + relFilename;
    const key = customConfigKeys.find(
      key => key === filename || key.indexOf(filename + ".") !== -1
    );

    if (key) {
      return customConfig[key];
    }

    return defaultValue;
  };

  // the site specific GraphQL endpoint; NULL if default is used instead
  const graphql = null; // siteConfig.graphql
  // const graphql = {
  //   endpoint: "http://graphql-sites.lsbolagen.se/site-NNN"
  // };

  // the site specific img and SVG directories
  const imgDir = siteId;
  const svgDir = "/svg";

  // the site specific default image properties
  const imgParams = {
    imgDir,
    svgDir,
    cloudinary
  };

  // arguments passed to the default site
  const passArgs = {
    siteId,
    i18n,
    graphql,
    siteScreens,
    siteTemplates,
    imgParams,
    store: siteConfig.store
  };

  const defaultSite = DefaultSite(passArgs);

  // the image shown on top-left
  const brand = {
    cloudinary: cloudinary,
    sizes: {
      mobilePortrait: 170,
      mobileLandscape: 250,
      mdUp: 331
    },
    src: imgDir + "/title.svg",
    aspect: 0.1118
  };

  const pages = {
    ...siteScreens,
    // merge the site's custom pages
    ...siteCustomPages
  };

  // the site's page templates
  const siteAllPages = defaultSite.utils.mergeSiteScreens(pages);

  // merge the default with the site's redirects (the site overrides the default redirects)
  const siteMergedRedirects =
    defaultSite.utils.mergeSiteRedirects(siteRedirects);

  // a "hard" redirect  (nginx/apache)
  const isHardRedirect = r => r.isHard || r.isReg;

  // exclude "hard" redirects (nginx/apache)
  const siteAllRedirects = siteMergedRedirects.filter(r => !isHardRedirect(r));

  // include only "hard" redirects (nginx/apache)
  const siteHardRedirects = siteMergedRedirects.filter(isHardRedirect);

  // the site meta/links (aka Helmet)
  // TODO see also https://html.spec.whatwg.org/multipage/semantics.html#standard-metadata-names
  // TODO see also Yoast metatags: https://yoast.com/
  const siteHelmet = {
    title: title,
    meta: {
      name: {
        // these overrides the default scripts/webpack/inject-helmet.js (they are "redundant" intentionally !!!)
        generator: joinNonEmptyStrings(
          packageInfo.name,
          packageInfo.gitInfo.version,
          " "
        ),
        author: packageInfo.author.company
      }
    }
    // https://developers.google.com/web/fundamentals/performance/resource-prioritization#preconnect
    // there is no point of preconnecting the self, at the time the browser reads this the connection/dns is already resolved
    /*link: [
      {
        rel: "preconnect",
        href: window.location.origin
      },
      {
        rel: "dns-prefetch",
        href: window.location.origin
      }
    ]*/
  };

  // the site's route resolver/pathfinder
  const pathfinder = pathfinderGenerator(siteAllPages, siteAllRedirects);

  // the site's page passthrough-arguments (a page might need/use them!)
  const pageParams = {
    siteId,
    imgParams: {
      ...imgParams,
      utils: {
        get: imgResolver
      }
    },
    pathfinder,
    i18n,
    graphql: siteConfig.graphql
  };

  // the site specific CSS classname
  const className =
    siteClassname + (defaultSite.className ? " " + defaultSite.className : "");

  // the shared cardDeck items shows both on page-bottom as well as in main-menu
  const imageFootnoteCardDeck = ImageFootnoteCardDeck({
    ...pageParams,
    items: [6, 6, 12]
  });

  imageFootnoteCardDeck.items = imageFootnoteCardDeck.items.map(item => {
    return {
      ...item,
      img: {
        ...item.img,
        sizes: {
          desktop: 267
        },
        aspect: 0.606
      }
    };
  });

  // the site specific static data
  const staticContent = {
    breadcrumbs: Breadcrumbs(pageParams),

    menubarHeader: menuBarHeader(pageParams),
    paymentMethods: paymentMethods(pageParams),
    imageFootnoteCardDeck: imageFootnoteCardDeck,
    gdpr: GDPRCookieController(pageParams),
    searchEngine: SearchEngine(pageParams),

    productBrandInfo: ProductBrandInfo(pageParams),
    campaign: Campaign(pageParams),
    searchBanner: SearchBanner(pageParams),
    buyInfo: BuyInfo(pageParams),
    cookiePolicy: CookiePolicy(pageParams),
    integrityPolicy: IntegrityPolicy(pageParams),
    customerServiceLead: CustomerServiceLead(pageParams),
    customerServiceContact: CustomerServiceContact(pageParams),
    customerServiceFootnote: CustomerServiceFootnote(pageParams),

    notFound404: NotFound404(pageParams),

    checkout: {
      shoppingCart: CheckoutShoppingCart(pageParams),
      relatedProducts: CheckoutRelatedProducts(pageParams),
      shipmentPayment: CheckoutShipmentPayment(pageParams),
      otherOptions: CheckoutOtherOptions(pageParams),
      deliveryInfo: CheckoutDeliveryInfo(pageParams),
      subtotal: CheckoutSubtotal(pageParams),
      placeOrder: CheckoutPlaceOrder(pageParams)
    }
  };

  // merge the site-specific static content
  Object.keys(siteStaticContent).forEach(
    key =>
    (staticContent[key] =
      "function" === typeof siteStaticContent[key]
        ? siteStaticContent[key](pageParams)
        : siteStaticContent[key])
  );

  // the site configuration
  const config = {
    ...defaultSite,
    ...imgParams,
    imgResolver,
    ...staticContent,

    //...siteCurrency,
    i18n,
    siteId,
    pathfinder,
    title,
    brand: deepmerge(brand, siteLogo),
    pages: siteAllPages,
    redirects: siteAllRedirects,
    hardRedirects: siteHardRedirects,
    helmet: deepmerge(defaultSite.helmet, siteHelmet),
    className,

    // merge the site specific widgets identities
    ...deepmerge(
      {
        googleReCaptcha: null
      },
      widgetsIdentities
    ),

    // add the remaining properties that are dependent on the site `config`
    marquee: MarqueeWidget(pageParams),

    footer: footer({
      ...pageParams,
      paymentMethods: staticContent.paymentMethods,
      title
    }),

    security: {
      adminToolbox: trusted || allowAdminToolbox(siteId), // allow admin toolbox
      enabled: trusted || allowUserLogin(siteId), // allow user logging
      enforceStrongPassword: false // strong password
    }
  };

  config.mainMenu = MainMenu({
    ...pageParams,
    imageFootnoteCardDeck: config.imageFootnoteCardDeck
  });

  config.supportsTagDiscount = i18n.PRODUCT_DISCOUNT_TAG;

  return config;
}
