export default ({ i18n }) => {
  const _i18n = i18n.pages.CheckoutSubtotal;

  return {
    //className: "my-4",
    i18n: {
      cartValue: _i18n.CART_VALUE,
      cartDiscount: _i18n.CART_DISCOUNT,
      cartSubtotal: _i18n.CART_SUBTOTAL,
      shippingValue: _i18n.SHIPPING_VALUE,
      vatValue: _i18n.VAT_VALUE,
      orderValue: _i18n.ORDER_VALUE,
      assemblyValue: _i18n.ASSEMBLY_VALUE,
      installValue: _i18n.INSTALL_VALUE,
      orderingValue: _i18n.ORDERING_VALUE,
      extraValue: _i18n.EXTRA_VALUE
    }
  };
};
